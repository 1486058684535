import React from 'react';
import { bool, shape, string } from 'prop-types';
import { Box, Flex, Heading, Image, Text, LinearProgress } from '@magalu/stereo-ui';
import { magaluCompanies } from '@magalu/mixer-utils';
import SellerStamp from '../SellerStamp/SellerStamp';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { getSallesLabel, getSellerRole, handleScoreColor } from './SellerScore.utils';
import { IconContainer, NewSellerBadge } from './SellerScore.styles';
var MAX_PROGRESS_BAR = 100;
var MAX_SCORE = 5;
var SCORE_VALUE_SCALE = MAX_PROGRESS_BAR / MAX_SCORE;
function SellerScore(_ref) {
  var _seller$details, _magaluCompanies$sell, _seller$details2, _seller$details2$deli, _seller$details3, _seller$details3$deli, _seller$details4, _seller$details5, _seller$details6, _seller$details7, _seller$details8;
  var _ref$static = _ref["static"],
    isMagaluCompany = _ref$static.isMagaluCompany,
    seller = _ref$static.seller;
  var isNewSeller = (seller == null ? void 0 : (_seller$details = seller.details) == null ? void 0 : _seller$details.score) === null;
  var sellerRole = getSellerRole(isMagaluCompany, seller);
  var sellerIcon = isMagaluCompany ? (_magaluCompanies$sell = magaluCompanies[seller.id]) == null ? void 0 : _magaluCompanies$sell.icon : 'https://wx.mlcdn.com.br/site/shared/sellers/third-party.png';
  var labelText = ['Ruim', 'Ótimo'];
  var getScoreLabel = function getScoreLabel(score) {
    return "".concat(score.toFixed(2)).substring(0, 3);
  };
  var deliveryRateStatus = seller == null ? void 0 : (_seller$details2 = seller.details) == null ? void 0 : (_seller$details2$deli = _seller$details2.deliveryRate) == null ? void 0 : _seller$details2$deli.status;
  var deliveryRateMessage = seller == null ? void 0 : (_seller$details3 = seller.details) == null ? void 0 : (_seller$details3$deli = _seller$details3.deliveryRate) == null ? void 0 : _seller$details3$deli.message;
  var sellerTotalSalles = seller == null ? void 0 : (_seller$details4 = seller.details) == null ? void 0 : _seller$details4.totalSales;
  var getDeliveryIcon = function getDeliveryIcon() {
    var icons = {
      success: 'DeliverySuccess',
      warning: 'DeliveryWarning'
    };
    return icons[deliveryRateStatus] || 'DeliveryDefault';
  };
  return React.createElement(Box, null, React.createElement(Flex, {
    gap: 3,
    height: 64,
    mb: isNewSeller && !isMagaluCompany && 10
  }, sellerIcon && React.createElement(IconContainer, null, React.createElement(Image, {
    src: sellerIcon,
    imageWidth: 64,
    imageHeight: 64,
    alt: seller.description,
    "data-testid": "seller-icon"
  }), isNewSeller && !isMagaluCompany && React.createElement(NewSellerBadge, {
    "data-testid": "new-seller"
  }, "Novo")), React.createElement(Flex, {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    lineHeight: 1,
    gap: 1
  }, React.createElement(Heading, {
    color: "attributeLabel",
    fontWeight: "medium",
    lineHeight: "input",
    fontSize: 3
  }, seller.description), React.createElement(Text, {
    color: "attributeLabel",
    textAlign: "left",
    fontWeight: "regular",
    "data-testid": "seller-role",
    fontSize: 2
  }, sellerRole))), ((_seller$details5 = seller.details) == null ? void 0 : _seller$details5.score) && !isMagaluCompany && React.createElement(Box, {
    pt: 26,
    mt: 1,
    flexDirection: "column"
  }, React.createElement(LinearProgress, {
    max: 100,
    height: "16px",
    value: ((_seller$details6 = seller.details) == null ? void 0 : _seller$details6.score) * SCORE_VALUE_SCALE,
    label: getScoreLabel((_seller$details7 = seller.details) == null ? void 0 : _seller$details7.score),
    color: handleScoreColor((_seller$details8 = seller.details) == null ? void 0 : _seller$details8.score),
    bullets: {
      quantity: 5,
      size: 6
    }
  }), React.createElement(Flex, {
    px: 3,
    pt: 1,
    mt: 1,
    justifyContent: "space-between",
    fontWeight: "regular",
    fontSize: 0
  }, labelText == null ? void 0 : labelText.map(function (item) {
    return React.createElement(Text, {
      key: "label-".concat(item),
      color: "#697277"
    }, item);
  })), !!deliveryRateMessage && sellerTotalSalles && !isNewSeller && React.createElement(Flex, {
    "data-testid": "seller-stamps"
  }, React.createElement(SellerStamp, {
    iconName: "TotalSales",
    iconDataTestId: "sallesIcon",
    title: getSallesLabel(sellerTotalSalles),
    message: "Produtos vendidos"
  }), React.createElement(SellerStamp, {
    iconName: getDeliveryIcon(),
    iconDataTestId: "delivery-".concat(deliveryRateStatus),
    title: "Entrega",
    message: deliveryRateMessage,
    borderLeft: "1px solid #F0F3F4"
  }))));
}
SellerScore.defaultProps = {
  "static": {
    isMagaluCompany: false,
    seller: {
      description: '',
      details: {},
      id: ''
    }
  }
};
process.env.NODE_ENV !== "production" ? SellerScore.propTypes = {
  "static": shape({
    isMagaluCompany: bool,
    seller: shape({
      description: string,
      id: string
    })
  })
} : void 0;
SellerScore.ssr = true;
export default withLayoutProps(SellerScore);