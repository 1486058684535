import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { string, number, shape, oneOfType, bool, arrayOf, array } from 'prop-types';
import Showcase from '@magalu/stereo-ui/organisms/Showcase';
import { usePublisher } from '@magalu/mixer-publisher';
import { useCart } from '@magalu/mixer-graphql';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { handleFactory, parseProducts } from './ShowcaseItem.utils';
var ShowcaseSidebar = dynamic(function () {
  return import('./ShowcaseSidebar');
});
var ShowcaseItem = function ShowcaseItem(props) {
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showSidebar = _useState2[0],
    setShowSidebar = _useState2[1];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var carouselConfig = props.carouselConfig,
    customerId = props.customerId,
    disableSidebar = props.disableSidebar,
    id = props.id,
    inCashDescription = props.inCashDescription,
    jsonLD = props.jsonLD,
    pageId = props.pageId,
    pagination = props.pagination,
    placeId = props.placeId,
    position = props.position,
    productId = props.productId,
    categories = props.categories,
    subCategories = props.subCategories,
    _props$products = props.products,
    showcaseProducts = _props$products === void 0 ? [] : _props$products,
    productTitleAs = props.productTitleAs,
    showDiscount = props.showDiscount,
    showDiscountOnlyCashInfo = props.showDiscountOnlyCashInfo,
    showInCashInfo = props.showInCashInfo,
    structure = props.structure,
    slidesPerViewBetterOffer = props.slidesPerViewBetterOffer,
    title = props.title,
    titleAs = props.titleAs,
    titleSize = props.titleSize,
    type = props.type;
  var _useCart = useCart(),
    _useCart2 = _slicedToArray(_useCart, 1),
    addToCart = _useCart2[0].addToCart;
  var _ref = (structure == null ? void 0 : structure.route) || {},
    storeId = _ref.storeId;
  var products = parseProducts(showcaseProducts, {
    inCashDescription: inCashDescription
  });
  var _handleFactory = handleFactory(props)({
      addToCart: addToCart,
      publish: publish,
      setShowSidebar: setShowSidebar
    }),
    eventData = _handleFactory.eventData,
    handleClick = _handleFactory.handleClick,
    handleOnLoadMore = _handleFactory.handleOnLoadMore,
    handleProductView = _handleFactory.handleProductView;
  useEffect(function () {
    if (products == null ? void 0 : products.length) {
      publish('productlist:impression', _extends({}, eventData, {
        products: products
      }));
    }
  }, [showcaseProducts]);
  return React.createElement(React.Fragment, null, !!(products == null ? void 0 : products.length) && React.createElement(Showcase, _extends({
    key: "showcase-".concat(position),
    exhibition: type,
    data: _extends({}, props, {
      products: products
    }),
    onClick: handleClick,
    onProductView: handleProductView,
    slidesPerViewBetterOffer: slidesPerViewBetterOffer,
    jsonLD: jsonLD,
    titleAs: titleAs,
    titleSize: titleSize,
    productTitleAs: productTitleAs,
    onClickLoadMore: function onClickLoadMore() {
      return handleOnLoadMore();
    },
    showFullListBtn: Boolean(pagination == null ? void 0 : pagination.next) && !disableSidebar,
    showInCashInfo: showInCashInfo,
    showDiscount: showDiscount,
    showDiscountOnlyCashInfo: showDiscountOnlyCashInfo
  }, carouselConfig)), Boolean(pagination == null ? void 0 : pagination.next) && !disableSidebar && React.createElement(ShowcaseSidebar, {
    showcaseId: id,
    customerId: customerId,
    pageId: pageId,
    placeId: placeId,
    visible: showSidebar,
    storeId: storeId,
    title: title,
    type: type,
    categories: categories,
    subCategories: subCategories,
    productId: productId,
    showInCashInfo: showInCashInfo,
    showDiscount: showDiscount,
    showDiscountOnlyCashInfo: showDiscountOnlyCashInfo,
    onCloseSidebar: function onCloseSidebar() {
      return setShowSidebar(false);
    },
    structure: structure
  }));
};
process.env.NODE_ENV !== "production" ? ShowcaseItem.propTypes = {
  carouselConfig: shape({}),
  cartInfo: shape({}),
  categories: oneOfType([array, string]),
  customerId: string,
  designTokenId: string,
  disableSidebar: bool,
  extensions: arrayOf(shape({})),
  id: oneOfType([string, number]),
  inCashDescription: string,
  jsonLD: bool,
  pageId: string,
  pagination: shape({}),
  placeId: string,
  position: number,
  productId: string,
  products: arrayOf(shape({})),
  productTitleAs: string,
  showDiscount: bool,
  showDiscountOnlyCashInfo: bool,
  showFullListBtn: bool,
  showInCashInfo: bool,
  slidesPerViewBetterOffer: 2,
  spa: bool,
  structure: shape({
    data: shape({
      filters: arrayOf(shape({}))
    })
  }),
  subCategories: oneOfType([array, string]),
  title: string,
  titleAs: string,
  titleSize: oneOfType([number, string]),
  type: string
} : void 0;
ShowcaseItem.defaultProps = {
  carouselConfig: {},
  cartInfo: {},
  categories: undefined,
  customerId: '',
  designTokenId: '',
  disableSidebar: false,
  extensions: undefined,
  id: '',
  inCashDescription: undefined,
  jsonLD: false,
  pageId: '',
  pagination: {},
  placeId: '',
  position: 0,
  productId: '',
  products: [],
  productTitleAs: '',
  showDiscount: false,
  showDiscountOnlyCashInfo: false,
  showFullListBtn: true,
  showInCashInfo: false,
  slidesPerViewBetterOffer: number,
  spa: false,
  structure: {},
  subCategories: undefined,
  title: '',
  titleAs: '',
  titleSize: undefined,
  type: ''
};
ShowcaseItem.displayName = 'ShowcaseItem';
export default withLayoutProps(ShowcaseItem);